<template>
  <v-container>
    <!-- Header -->
    <v-container>
      <v-card color="secondary">
        <div class="text-h3 white--text pa-4 d-flex justify-center">Dev Tools</div>
      </v-card>
    </v-container>

    <v-container>
      <v-row>
        <v-col>
          <core-btn-primary @click="import_language()">Import All</core-btn-primary>
        </v-col>
        <v-col>
          <core-btn-primary @click="update_categories()">Update Categories</core-btn-primary>
        </v-col>
        <v-col>
          <core-btn-primary @click="goToAdminSite()">Admin</core-btn-primary>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  components: {},

  data: function () {
    return {
      users: [],
      userId: {},
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
  },

  methods: {
    import_language() {
      this.$django_api.get(`core/import`).then((response) => {
        window.alert("Import complete.")
      })
    },

    update_categories() {
      this.$django_api.post(`core/categories`).then((response) => {
        window.alert("Update complete.")
      })
    },

    goToAdminSite() {
      window.open(this.$django_api.defaults.baseURL + "admin/", "_blank")
    },
  },
  mounted() {},
}
</script>
